import React from 'react'
import ReactDOM from 'react-dom'
import HeaderApp from '../applications/components/HeaderApp'
import {
    componentExistsOnPage,
    getComponentProps,
    getRootElement,
} from '../applications/utils/ssr'
import CustomStoreHeaderApp from '../applications/components/CustomStoreHeaderApp'

if (componentExistsOnPage('HeaderApp')) {
    const props = getComponentProps('HeaderApp')

    ReactDOM.hydrate(<HeaderApp {...props} />, getRootElement('HeaderApp'))
}

if (componentExistsOnPage('CustomStoreHeaderApp')) {
  const props = getComponentProps('CustomStoreHeaderApp')

  ReactDOM.hydrate(
    <CustomStoreHeaderApp {...props} />,
    getRootElement('CustomStoreHeaderApp')
  )
}